import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { graphql } from 'gatsby';
import Observer from 'react-intersection-observer';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_innovation.scss';
import Bluekey from '../../static/assets/blue_key.svg';
import BluekeyReplica from '../../static/assets/blue_key_replica.svg';
import BluekeySecond from '../../static/assets/blue_key_second.svg';
import internalTeamImg from '../../static/img/innovation/internal_team.png';
import YellowKey from '../../static/assets/yellow_key.svg';
import * as globalAnimation from '../animations/globalAnimation';
import animationData from '../animations/bulb_animation.json';

import {
  // animateLeftImg,
  animateBulbImg,
  animateClientImg,
  animateIncubatorImage,
  animateImageRightx,
} from '../animations/Innovation';

export default class Innovation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    animateBulbImg(this.tl, '.animate-ideas');
    globalAnimation.animateTLD(
      this.tl,
      '#desc_one',
      '.svg-line__line_blue',
      '.svg_circle_blue',
      '.animate-desc'
    );
  }

  incubatorAnimation = InView => {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateIncubatorImage(
        tl,
        '.svg-line__line_blue_replica',
        '.svg_circle_blue_replica',
        '#title-incub',
        '#desc-incub',
        '.incub-img',
        '.incub-bg'
      );
    }
  };

  clientAnimation = InView => {
    const tl1 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateClientImg(
        tl1,
        '.client-title',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.client_desc',
        '.client-img'
      );
    }
  };

  teamAnimation = InView => {
    const tl2 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateImageRightx(
        tl2,
        '#title',
        '.svg-line__line_blue_second',
        '.svg_circle_blue_second',
        '#desc',
        '.team-img',
        '.bg-animate',
        '#btn_anim'
      );
    }
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    const {
      data: {
        markdownRemark: {
          frontmatter: { title, contentone, contenttwo, contentthree, contentfour },
        },
      },
    } = this.props;

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Innovation" />
        <Container fluid className="main_wrapper section-one">
          <Row>
            <Col>
              <div className="wrapper pb-0">
                <div className="page_heading_section text-center">
                  <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                  <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="bg-whitesmoke d-flex align-items-center justify-content-center mt-3 pb-lg-5">
            <Col xs={12} className="d-lg-none">
              <Lottie options={defaultOptions} height={250} width={250} isClickToPauseDisabled>
                <img src={contentone.image.publicURL} className="img-fluid" alt="innovation img" />
              </Lottie>
            </Col>
            <Col xs={12} lg={{ span: 4 }}>
              <div className="position-relative line line-horizontal py-3 py-lg-0">
                <div className="wrapper px-0 pt-0">
                  <h5 className="text-appcolor text-capitalize" id="desc_one">
                    {contentone.title}
                  </h5>
                  <Bluekey className="mb-2" />
                  <div className="animate-desc">
                    <p>{contentone.descriptionOne}</p>
                    <p>{contentone.descriptionTwo}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={{ span: 3, offset: 1 }} className="d-none d-lg-block">
              <div className="position-relative line line-horizontal py-3 py-lg-0">
                <div className="wrapper pt-0 px-0 animate-ideas">
                  <Lottie options={defaultOptions} height={400} width={400} isClickToPauseDisabled>
                    <img
                      src={contentone.image.publicURL}
                      className="img-fluid"
                      alt="innovation img"
                    />
                  </Lottie>
                </div>
              </div>
            </Col>
          </Row>
          <Observer onChange={this.incubatorAnimation} triggerOnce>
            <Row className="py-lg-5 mt-lg-5 py-3">
              <Col xs={12} lg={6} className="">
                <div className="d-lg-none">
                  <div>
                    <img src={contenttwo.image.publicURL} className="img-fluid" alt="" />
                  </div>
                </div>
                <div className="bg-orange square mx-auto my-lg-4 my-lg-0 incub-bg d-none d-lg-block">
                  <div className="temp_translate">
                    <img src={contenttwo.image.publicURL} className="img-fluid incub-img" alt="" />
                  </div>
                </div>
              </Col>

              <Col xs={12} lg={{ span: 5 }}>
                <div className="position-relative line line-horizontal px-2 py-3 py-lg-0">
                  <div id="title-incub">
                    <h5 className="text-appcolor">{contenttwo.title}</h5>
                  </div>
                  <BluekeyReplica className="mb-2" />
                  <div id="desc-incub">
                    <p>{contenttwo.descriptionOne}</p>
                    <p>{contenttwo.descriptionTwo}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Observer onChange={this.clientAnimation} triggerOnce>
            <Row className="wrapper">
              <Col xs={12} className="d-lg-none">
                <img src={contentthree.image.publicURL} alt="" className="img-fluid " />
              </Col>
              <Col className="order-1 order-lg-2 client-img-wrapper h-auto d-none d-lg-flex">
                <div className="align-self-end client-img">
                  <img src={contentthree.image.publicURL} alt="" className="img-fluid " />
                </div>
              </Col>
              <Col xs={12} lg={8} className="order-2 order-lg-1 client-content-wrapper">
                <div className="text-white bg-appcolor-dark wrapper-innovation h-100 d-none d-lg-block">
                  <div className="client-title">
                    <h5>{contentthree.title}</h5>
                  </div>
                  <YellowKey width="120px" className="mb-2" />
                  <p id="desc_client" className="mb-0">
                    {contentthree.description}
                  </p>
                </div>
                <div className="py-3 d-lg-none d-block">
                  <div className="client-title">
                    <h5 className="text-appcolor text-bold">{contentthree.title}</h5>
                  </div>
                  <YellowKey width="120px" className="mb-2" />
                  <p id="desc_client" className="mb-0">
                    {contentthree.description}
                  </p>
                </div>
              </Col>
            </Row>
          </Observer>

          <Observer onChange={this.teamAnimation} triggerOnce>
            <Row className="py-lg-5 mt-lg-5 py-3">
              <Col xs={12} className="d-lg-none">
                <img src={internalTeamImg} className="img-fluid" alt="" />
              </Col>
              <Col xs={12} lg={6} className="d-none d-lg-block">
                <div className="bg-appcolor-light bg-animate square mx-auto my-lg-4 my-lg-0">
                  <div>
                    <img src={internalTeamImg} className="img-fluid team-img" alt="" />
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={{ span: 5 }}>
                <div className="position-relative line line-horizontal px-2 py-3 py-lg-0">
                  <h5 className="text-appcolor" id="desc">
                    {contentfour.title}
                  </h5>
                  <BluekeySecond className="mb-2" />
                  <p id="desc">{contentfour.description}</p>
                </div>
              </Col>
            </Row>
          </Observer>
        </Container>
      </Layout>
    );
  }
}
export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "innovation" } }) {
      frontmatter {
        page
        title
        contentone {
          title
          descriptionOne
          descriptionTwo
          image {
            publicURL
          }
        }
        contenttwo {
          title
          descriptionOne
          descriptionTwo
          image {
            publicURL
          }
        }
        contentthree {
          title
          description
          image {
            publicURL
          }
        }
        contentfour {
          title
          description
          image {
            publicURL
          }
        }
      }
    }
  }
`;
