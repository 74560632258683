import { Power1, Power2 } from 'gsap';

export function animateLeftImg(tl, background, img) {
  return tl
    .staggerFrom(
      img,
      1.3,
      {
        x: '-150',
        y: '70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerFrom(
      background,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateBulbImg(tl, img) {
  return tl.staggerFrom(
    img,
    0.7,
    {
      x: 0,
      y: '70px',
      opacity: 0,
      ease: Power1.easeOut,
    },
    0,
    0.7
  );
}

export function animateClientImg(tl, title, svgLine, svgCircle) {
  const o = 0.5;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo('#desc_client', o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to(
      '.line-horizontal',
      0.5,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      1
    );
}

export function animateIncubatorImage(tl, svgLine, svgCircle, title, desc, image, container) {
  const o = 0.7;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerFrom(
      container,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      1
    )
    .staggerFrom(
      image,
      1.3,
      {
        x: '70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      1
    );
}

export function animateTLDx(tl, title, svgLine, svgCircle, desc) {
  const o = 0.7;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to(
      '.line-horizontal',
      0.5,
      { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut },
      1
    );
}

export function animateImageRightx(tl, title, svgLine, svgCircle, desc, image, container, button) {
  const o = 0.7;
  return tl
    .set('.line-horizontal', { x: 0 })
    .staggerFrom(
      title,
      o,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFromTo(svgLine, 0.5, { attr: { width: 0 } }, { attr: { width: 88 }, clearProps: 'all' })
    .staggerFromTo(
      svgCircle,
      0.5,
      { strokeDasharray: 16, strokeDashoffset: 16 },
      { strokeDashoffset: 0, clearProps: 'all' }
    )
    .staggerFromTo(desc, o, { opacity: 0, y: 15 }, { opacity: 1, y: 0 }, o)
    .to('.line-horizontal', 0.5, { autoAlpha: 1, x: 0, clearProps: 'all', ease: Power2.easeOut }, 1)
    .staggerTo(
      image,
      1.3,
      {
        x: '70px',
        y: '-70px',
        opacity: 1,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      container,
      1.3,
      {
        x: -120,
        y: 20,
        opacity: 0,
        ease: Power1.easeOut,
      },
      0,
      o
    )
    .staggerFrom(
      button,
      1.9,
      {
        opacity: 0,
        y: 40,
        ease: Power1.easeOut,
      },
      0,
      1.9
    );
}
